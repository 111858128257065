body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #222;
  color: #fff;
  margin: 8px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.all {
  max-width: 900px;
  margin: 0 auto;
}

.small {
  font-size: 0.7em;
  margin: 4px;
}

.wrap {
  display: flex;
  flex-wrap: wrap;
}
.pointer {
  cursor: pointer;
}

.highlight {
  padding: 4px 6px;
  border-radius: 8px;
  font-size: 0.8em;
  border: 1px solid #222;
}
.hlFirst {
  background-color: #cc3;
  color: #333;
}
a {
  text-decoration: none;
}
a:hover .hlFirst {
  background-color: #ee5;
}
.hlSecond {
  background-color: #cc2;
  color: #333;
}
.hlThird {
  background-color: #880;
  color: #222;
}
.hlFourth {
  background-color: #660;
  color: #222;
}

.groupLeft {
  background-color: #a00;
}
.groupG {
  background-color: #4a4;
}
.groupSD {
  background-color: #f00;
}
.groupRe {
  background-color: #990;
}
.groupEpp {
  background-color: #3af;
}
.groupEcr {
  background-color: #00f;
}
.groupId {
  background-color: #009;
}
.groupNi {
  background-color: #888;
}
a:hover .groupNi {
  background-color: #666;
}
a .groupNi {
  color: #ddd;
}

.dot {
  border-radius: 50%;
  width: 10px;
  height: 10px;
  display: inline-block;
}
.rounded {
  border-radius: 16px;
}

.plus {
  color: green;
  padding: 1px;
  border-top: 0;
  border-bottom: 1px;
  border-left: 0;
  border-right: 0;
  border-style: solid;
}

.headerCountry a {
  color: #ccc;
  text-decoration: none;
}

.underline {
  color: #ccc;
  padding: 4px 60px 4px 4px;
  border-top: 0;
  border-bottom: 2px;
  border-left: 0;
  border-right: 0;
  border-style: solid;
  margin: 8px;
  font-weight: bold;
}
.margin12 {
  margin: 12px;
}
.margin8 {
  margin: 8px;
}
.width180 {
  width: 180px;
}
.padding8 {
  padding: 8px;
}

.bubble {
  position: relative;
  left: -8px;
  top: -10px;
  background-color: #cc3;
  border-radius: 50%;
  padding: 0px 4px;
  color: #333;
  font-size: 10px;
}

.selected {
  color: #222;
  font-weight: bold;
  background-color: #aaa;
  border-radius: 4px;
  margin: 0 4px;
  padding: 0 2px;
}
.hoverable {
  padding: 0 2px;
}
.hoverable:hover {
  background-color: #444;
  border-radius: 4px;
}
.hoverable2:hover {
  opacity: 0.8;
}

/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltipText {
  visibility: hidden;
  min-width: 120px;
  padding: 5px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  top: -24px;
 
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltipText {
  visibility: visible;
}

.countryDiv {
  width: 800px;
}

.absolute {
  position: absolute;
}

.floatRight {
  float: right;
}

.floatLeft {
  float: left;
  overflow: visible;
}
.collapsed {
  visibility: collapse;
  height: 0;
}

.mapTable {
  display: grid;
  grid-template-columns: 600px 1fr;
  grid-template-rows: 520px 1fr;
}
.mapPart {
  grid-area: 1 / 1 / 2 / 2;
}
.mapMenuPart {
  width: 330px;
  z-index: 99;
}

.parentOfHalf {
  display: flex;
}
.half {
  width: 50%;
  flex: 1;
}

.bigMargin {
  margin: 24px;
}

.group-column {
  width: calc(100vw/7);
}

.flex {
  display: flex;
}

.dot-country {
  width: 180px;
}